

.footercontainet {
  width: 100%;
  position: relative;
  background-color: #f5fbf9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 0px;
  box-sizing: border-box;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: Roboto;
}
.footercontainer-parent {
    align-self: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 100px 50px 200px;
}
  
.footercontainer2 {

	  width: 100%;
	  height: 100%;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;
	  justify-content: flex-start;
	  gap: 30px;
  }
 .image-3-icon {
  	width: 160px;
  	position: relative;
  	height: 42.2px;
  	object-fit: cover;
}
.footercollectorbabu-is-your {
  	align-self: stretch;
  	position: relative;
  	line-height: 26px;
}
.iconoirtelegram {
  	width: 40px;
  	position: relative;
  	border-radius: 30px;
  	height: 40px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.footermail-icon {
  	width: 40px;
  	position: relative;
  	border-radius: 50px;
  	height: 40px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.footersocials {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 12px;
}
.footercontainer {
  	width: 341px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 26px;
  	font-size: 15px;
  	color: rgba(12, 12, 12, 0.7);
}
.contact-information {
  	align-self: stretch;
  	position: relative;
  	line-height: 20px;
  	font-weight: 500;
}
.footeremail {
  	align-self: stretch;
  	position: relative;
}
.footercollectorbabuexamplecom {
  	align-self: stretch;
  	position: relative;
  	font-size: 14px;
  	line-height: 20px;
}
.footeremail-id {
  	width: 184px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 8px;
}
.footere-mail {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 16px;
}
.footerframe-icon {
  	width: 50px;
  	position: relative;
  	border-radius: 50px;
  	height: 50px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.footercontacts {
  	width: 250px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 30px;
}
.footer-services {
  	width: 300px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 30px;
}
.footerlink1 {
  	/* position: absolute;
  	width: 100%;
  	top: 0%;
  	left: 0%; */
  	line-height: 20px;
  	display: inline-block;
}
.footerlink {
  	/* align-self: stretch;
  	position: relative; */
  	height: 20px;
}
.footerlink2 {
  	/* align-self: stretch;
  	position: relative; */
  	height: 20px;
  	color: rgba(0, 0, 0, 0.7);
}
.footerlink-parent, .footerlink-group{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footercontainer1 {
  	width: 100%;
  	display: flex;
  	flex-direction: row;
  	align-items: flex-start;
  	justify-content: space-between;
  	gap: 20px;
  	color: rgba(12, 12, 12, 0.7);
}
.footercopyright-collector {
  	position: relative;
  	line-height: 20px;
}
.footerprivacy-policy1 {
  	margin: 0;
  	font-family: inherit;
  	font-size: inherit;
  	padding-left: 17px;
}
.footerprivacy-policy {
  	position: relative;
  	text-decoration: underline;
  	line-height: 20px;
}
.footer-policy2{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerpolicies {
  display: flex;
  flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 2px;
  	text-align: left;
}
.footerterms {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: space-between;
  	padding: 0px 200px;
  	text-align: center;
  	font-size: 13px;
  	color: rgba(12, 12, 12, 0.7);
  }
  
  @media (max-width: 550px) {
    .footercontainet {
      width: 100vw;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0px 0px 50px;
      gap: 40px;
      text-align: left;
      font-size: 15px;
      color: rgba(12, 12, 12, 0.7);
      font-family: Roboto;
    }
    .footercontainer-parent{
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 30px;
      padding: 50px 0px 30px 0px;
    }
    .footercontainer2 {
      order: 0;
        width: 100%;
        height: 195px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 30px;
    }
    .image-3-icon {
      width: 160px;
  	position: relative;
  	height: 42.2px;
  	object-fit: cover;
}
.footercollectorbabu-is-your {
  	align-self: stretch;
  	position: relative;
  	line-height: 26px;
}
.iconoirtelegram {
  	width: 40px;
  	position: relative;
  	border-radius: 30px;
  	height: 40px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.mail-icon {
  	width: 40px;
  	position: relative;
  	border-radius: 50px;
  	height: 40px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.footersocials {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 12px;
}
.footercontact-information {
  	position: relative;
  	line-height: 20px;
  	font-weight: 500;
}
.services-wrapper {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: center;
}
.footerlink1 {
  	position: absolute;
  	width: 100%;
  	top: 0%;
  	left: 0%;
  	line-height: 20px;
  	display: inline-block;
}
.footerlink {
  	align-self: stretch;
  	position: relative;
  	height: 20px;
}
.footerlink2 {
  	width: 89px;
  	position: relative;
  	height: 20px;
  	color: rgba(0, 0, 0, 0.7);
}
.footerlink-parent {
  	width: 138px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 16px;
}
.footerlink4 {
  	width: 63px;
  	position: relative;
  	height: 20px;
}
.footerlink6 {
  	align-self: stretch;
  	position: relative;
  	height: 20px;
  	color: rgba(12, 12, 12, 0.7);
}
.footerlink-group {
  	width: 116px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 16px;
  	color: rgba(0, 0, 0, 0.7);
}
.footercontainer1 {
    width: auto;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: center;
  	gap: 80px;
  	color: rgba(12, 12, 12, 0.7);
}
.footer-services {
   order: 1;
  	width: 341px;
  	display: flex;
  	flex-direction: column;
  	align-items: start;
  	justify-content: flex-start;
  	gap: 30px;
  	font-size: 16px;
  	color: #000;
}
.footercontact-information {
  	align-self: stretch;
  	position: relative;
  	font-size: 16px;
  	line-height: 20px;
  	font-weight: 500;
}
.footeremail {
  	align-self: stretch;
  	position: relative;
}
.footercollectorbabuexamplecom {
  	align-self: stretch;
  	position: relative;
  	font-size: 14px;
  	line-height: 20px;
}
.footeremail-id {
  	width: 184px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 6px;
}
.footere-mail {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 16px;
}
.frame-icon {
  	width: 50px;
  	position: relative;
  	border-radius: 50px;
  	height: 50px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.footercontacts {
  order: 2;
  	/* align-self: stretch; */
    width: 341px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	/* padding: 0px 26px 30px; */
  	gap: 30px;
  	color: #000;
}
.footercopyright-collector {
  width: 360px;
  text-align: center;
  	line-height: 20px;
  	display: flex;
}
.footer-policy2{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.refund {
  	position: relative;
  	text-decoration: underline;
  	line-height: 20px;
  	white-space: pre-wrap;
}
.terms-of-service {
  	position: relative;
  	text-decoration: underline;
  	line-height: 20px;
}
.footerpolicies {
    width: 250px;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: center;
  	gap: 16px;
}
.policies1 {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: center;
}
.container1 {
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: flex-start;
  	padding: 0px 20px;
  	gap: 11px;
  	text-align: left;
  	font-size: 14px;
}
.footerterms {
    width: 341px;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 18px;
  	text-align: center;
  	font-size: 13px;
}


 } 

 @media (max-width: 768px) {
    .footercontainet {
      width: 100vw;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0px 0px 50px;
      gap: 40px;
      text-align: left;
      font-size: 15px;
      color: rgba(12, 12, 12, 0.7);
      font-family: Roboto;
    }
    .footercontainer-parent{
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 30px;
      padding: 50px 0px 30px 0px;
    }
    .footercontainer2 {
      order: 0;
        width: 100%;
        height: 195px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 30px;
    }
    .image-3-icon {
      width: 160px;
  	position: relative;
  	height: 42.2px;
  	object-fit: cover;
}
.footercollectorbabu-is-your {
  	align-self: stretch;
  	position: relative;
  	line-height: 26px;
}
.iconoirtelegram {
  	width: 40px;
  	position: relative;
  	border-radius: 30px;
  	height: 40px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.mail-icon {
  	width: 40px;
  	position: relative;
  	border-radius: 50px;
  	height: 40px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.footersocials {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 12px;
}
.footercontact-information {
  	position: relative;
  	line-height: 20px;
  	font-weight: 500;
}
.services-wrapper {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: center;
}
.footerlink1 {
  	position: absolute;
  	width: 100%;
  	top: 0%;
  	left: 0%;
  	line-height: 20px;
  	display: inline-block;
}
.footerlink {
  	align-self: stretch;
  	position: relative;
  	height: 20px;
}
.footerlink2 {
  	width: 89px;
  	position: relative;
  	height: 20px;
  	color: rgba(0, 0, 0, 0.7);
}
.footerlink-parent {
  	width: 138px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 16px;
}
.footerlink4 {
  	width: 63px;
  	position: relative;
  	height: 20px;
}
.footerlink6 {
  	align-self: stretch;
  	position: relative;
  	height: 20px;
  	color: rgba(12, 12, 12, 0.7);
}
.footerlink-group {
  	width: 116px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 16px;
  	color: rgba(0, 0, 0, 0.7);
}
.footercontainer1 {
    width: auto;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: center;
  	gap: 80px;
  	color: rgba(12, 12, 12, 0.7);
}
.footer-services {
   order: 1;
  	width: 341px;
  	display: flex;
  	flex-direction: column;
  	align-items: start;
  	justify-content: flex-start;
  	gap: 30px;
  	font-size: 16px;
  	color: #000;
}
.footercontact-information {
  	align-self: stretch;
  	position: relative;
  	font-size: 16px;
  	line-height: 20px;
  	font-weight: 500;
}
.footeremail {
  	align-self: stretch;
  	position: relative;
}
.footercollectorbabuexamplecom {
  	align-self: stretch;
  	position: relative;
  	font-size: 14px;
  	line-height: 20px;
}
.footeremail-id {
  	width: 184px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 6px;
}
.footere-mail {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 16px;
}
.frame-icon {
  	width: 50px;
  	position: relative;
  	border-radius: 50px;
  	height: 50px;
  	overflow: hidden;
  	flex-shrink: 0;
}
.footercontacts {
  order: 2;
  	/* align-self: stretch; */
    width: 341px;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	/* padding: 0px 26px 30px; */
  	gap: 30px;
  	color: #000;
}
.footercopyright-collector {
  width: 360px;
  text-align: center;
  	line-height: 20px;
  	display: flex;
}
.footer-policy2{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.refund {
  	position: relative;
  	text-decoration: underline;
  	line-height: 20px;
  	white-space: pre-wrap;
}
.terms-of-service {
  	position: relative;
  	text-decoration: underline;
  	line-height: 20px;
}
.footerpolicies {
    width: 250px;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: center;
  	gap: 16px;
}
.policies1 {
  	align-self: stretch;
  	display: flex;
  	flex-direction: row;
  	align-items: center;
  	justify-content: center;
}
.container1 {
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: flex-start;
  	padding: 0px 20px;
  	gap: 11px;
  	text-align: left;
  	font-size: 14px;
}
.footerterms {
    width: 341px;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	justify-content: flex-start;
  	gap: 18px;
  	text-align: center;
  	font-size: 13px;
}


 } 